<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmTier')" data-vv-scope="frmTier" novalidate="novalidate">
    <div class="row justify-content-md-center">

      <div class="col-12 col-md-12">
        <!--  Geral -->
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.section.general') }}</h5>
            <div class="row">
                <!-- Código -->
                <custom-input
                  v-model="tierLocal.codigo"
                  name="tierLocal.codigo"
                  :label="this.$i18n.t('form.tierLocal.codigo')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  :max-length="25"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('tierlocal.codigo')">
                </custom-input>
                <!-- Operação -->
                <div class="col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tierLocal.tipoOperacao') }}</label>
                  <v-select name="tierLocal.tipoOperacao" label="tierLocal.tipoOperacao" :clearable="false" :searchable="true" v-model="tierLocal.tipoOperacao"  :options="tipoOperacaoList">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                </div>

              <!-- Tipo Carga -->
              <div class="col-sm-4 form-group mb-4">
                <label>{{ this.$i18n.t('form.tierLocal.tipoCarga') }}</label>
                <v-select name="tierLocal.tipoCarga" label="tierLocal.tipoCarga" :clearable="false" :searchable="true" v-model="tierLocal.tipoCarga"  :options="tipoCargaList">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
              </div>
              </div>
          </div>
        </div>

        <!-- Desconsolidação -->
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.section.desconsolidacao') }}</h5>
            <div class="row">
              <div class="col-sm-4 form-group mb-4">
                <label>{{ this.$i18n.t('form.tierLocal.desconsolidacaoMoeda') }}</label>
                <v-select name="sigla" label="sigla" :clearable="false" :searchable="true" v-model="tierLocal.desconsolidacaoMoeda" @search="currencyFetchOptions" :options="currencyList"
                           @input="onSelectCurrency" :filter-by="currencyFilter" >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }} ({{ option.sigla }})
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }} ({{ option.sigla }})
                    </div>
                  </template>
                </v-select>
              </div>
              <custom-input
                v-model="tierLocal.desconsolidacaoValor"
                name="tierLocal.desconsolidacaoValor"
                :label="this.$i18n.t('form.tierLocal.desconsolidacaoValor')"
                type="text"
                rootClassName="col-sm-4 form-group mb-4"
                :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                :error="submitted && errors.first('tierLocal.desconsolidacaoValor')">
              </custom-input>
            </div>
          </div>
        </div>

        <!--  Taxas -->
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.section.taxes') }} (BRL)</h5>
            <div class="row">
              <div class="col-12 col-md-12">
                <advanced-multiple-form  :value="tierLocal.taxas" :valueTransform="taxesTransform" :options="taxesList" id-field="id"  labelField="nome" :no-options="'Buscar Taxas'" :tableFields="taxFields" v-bind:fetchOptions="taxaLogisticaExibicaoFetchOptions">
                  <template slot="formItem" slot-scope="row">
                    <td></td>
                    <td>{{ row.item.nome }}</td>
                    <td>
                      <custom-input
                        v-model="row.item.valor"
                        name="tierLocal.tax.valor"
                        type="text"
                        rootClassName="col-sm-4 form-group mb-0"
                        :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                        :error="submitted && errors.first('tierLocal.tierLocal.tax.valor')">
                      </custom-input>
                    </td>
                  </template>
                </advanced-multiple-form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveTier">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>

    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import AdvancedMultipleForm from '@/components/Forms/AdvancedMultipleForm.vue'

// Services
import CurrencyService from '@/services/CurrencyService'
import TierService from '@/services/TierService'
import TaxLogisticService from '@/services/TaxLogisticService'
export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.tier.title') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      taxFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('form.tierLocal.taxa.nome'),
          sortField: 'name',
          width: '60%',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'valor',
          title: this.$i18n.t('form.tierLocal.taxa.valor'),
          sortField: 'value',
          width: '40%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      taxesList: [],
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      tipoOperacaoList: [
        {
          id: 1,
          nome: 'Exportação'
        },
        {
          id: 2,
          nome: 'Importação'
        }
      ],
      tipoCargaList: [
        {
          id: 3,
          nome: 'FCL'
        },
        {
          id: 4,
          nome: 'LCL'
        }
      ],
      currencyList: [],
      //  Selected Values (Form)
      tierLocal: {
        codigo: null,
        tipoOperacao: null,
        tipoCarga: null,
        desconsolidacaoValor: null,
        desconsolidacaoMoeda: null,
        taxas: []
      },
      submitted: false
    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput,
    AdvancedMultipleForm
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    // Load basilares data
    CurrencyService.getCurrencies().then(res => {
      _this.hasData = res.data.hasData
      _this.currencyList = res.data.data
      _this.tierLocal.desconsolidacaoMoeda = _this.currencyList[0]

      // If is edit populate values
      let tierId = this.$route.params.id_tier

      if (tierId) {
        TierService.getTierLocal(tierId).then(tier => {
          _this.tierLocal = tier.data
          _this.tierLocal.tipoOperacao = _this.tipoOperacaoList.find(function (x) {
            return x.id === _this.tierLocal.tipoOperacao
          })
          _this.tierLocal.tipoCarga = _this.tipoCargaList.find(function (x) {
            return x.id === _this.tierLocal.tipoCarga
          })
        }).finally(() => {
          _this.isLoading = false
        })
      }
      // End edit
      _this.isLoading = false
      // CurrencyService.getCurrencies().then(res => {
      //   _this.currencyList = res.data.data
      //
      // })
    })
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TierLocalIndex' })
    },
    taxesTransform (value) {
      let objectValue = {
        id: value.id,
        nome: value.nome,
        valor: 0.00,
        idMoeda: 110
      }

      return objectValue
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    taxaLogisticaExibicaoFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      TaxLogisticService.getTaxs(filters).then(res => {
        this.hasData = res.data.hasData
        this.taxesList = res.data.data
        loading(false)
      })
    },
    currencyFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(temp) > -1 || option.sigla.toLowerCase().indexOf(temp) > -1)
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    onSelectCurrency (val) {
      this.tierLocal.desconsolidacaoMoeda = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_tier: object.id } }).href
    },

    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CurrencyService.getCurrencies(filters).then(res => {
        this.hasData = res.data.hasData
        this.currencyList = res.data.data
        loading(false)
      })
    },

    onSaveTier (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let tierData = {
            codigo: this.tierLocal.codigo,
            desconsolidacaoValor: this.tierLocal.desconsolidacaoValor,
            tipoOperacao: this.tierLocal.tipoOperacao.id,
            tipoCarga: this.tierLocal.tipoCarga.id,
            desconsolidacaoIdMoeda: this.tierLocal.desconsolidacaoMoeda.id,
            taxas: this.tierLocal.taxas.map(function (tax) {
              return {
                valor: tax.valor,
                idtaxaLogisticaExibicao: tax.id,
                idMoeda: 110
              }
            })
          }

          let tierId = this.$route.params.id_tier
          if (tierId) {
            TierService.editTierLocal(tierId, tierData).then(response => {
              _this.$router.push({ name: 'TierLocalIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TierService.newTierLocal(tierData).then(response => {
              _this.$router.push({ name: 'TierLocalIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }
</style>
